<div class="user-plan-label-component">
    <div class="plan-label-container">
        <div *ngIf="!appsumoPlanFeatures">
            <div class="plan-description">{{ userPlanName }}</div>
        </div>
        <div 
            *ngIf="appsumoPlanFeatures" 
            class="appsumo-subscription-container">
            <div class="plan-description">{{ appsumoPlanFeatures?.planDescription }}</div>
            <div 
                *ngIf="appsumoPlanFeatures.collections"
                class="appsumo-collections-count-container">
                <div class="plus-icon-container">
                    <img src="/img/green-plus-icon.svg" alt="+">
                </div>
                <div class="collections-count">{{ appsumoPlanFeatures?.collections }}</div>
            </div>
        </div>
    </div>
</div>