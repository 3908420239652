import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppsumoPlanFeatures } from '../../../profile/models/appsumo-plan-features';
import {PlanHelperService} from "../../services/plan-helper.service";
import { NgIf } from '@angular/common';

@Component({
    selector: 'user-plan-label',
    templateUrl: './user-plan-label.component.html',
    styleUrls: ['./user-plan-label.component.css'],
    standalone: true,
    imports: [NgIf]
})
export class UserPlanLabelComponent implements OnInit, OnDestroy {

    appsumoPlanFeatures: AppsumoPlanFeatures;
    organizationPlanChangeListener: Subscription;
    userPlanName: string;

    constructor(private planHelperService : PlanHelperService) {
    }

    ngOnInit() {
        this.setOrganizationPlanChangeListener();
    }

    setOrganizationPlanChangeListener = () => {
        this.organizationPlanChangeListener = this.planHelperService.organizationPlan$
            .subscribe(this.setOrganizationPlan)
    }

    setOrganizationPlan = async (plan: any) => {
        if (plan !== null) {
            this.userPlanName = this.planHelperService.getPlanDisplayNameWithPlatforms(plan);
            await this.setAppsumoPlanFeatures();
        }
    }

    setAppsumoPlanFeatures = async () => {
        this.appsumoPlanFeatures = await this.planHelperService.getAppsumoPlanFeatures();
    }

    ngOnDestroy() {
        this.organizationPlanChangeListener.unsubscribe();
    }
}
